import { tailwind } from '@theme-ui/presets'
import { merge } from "theme-ui"

const unDrawIndigo = `#3f51b5`
const unDrawIndigoSecondary = `#edeef7`

export default merge(tailwind, {
    colors: {
        base: unDrawIndigo,
        baseSecond: unDrawIndigoSecondary,
    },
})